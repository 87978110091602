<template>
  <div id="container">
    <div id="inviteContainer">
      <div class="logoContainer">
        <img class="text" src="@/assets/images/logo-light.png" alt="Logo">
      </div>
      <div class="acceptContainer" :class="{ 'loadIn': acceptContainerLoaded }">
        <div v-if="!signupForm">
          <form @submit.prevent="login">
            <h1>WELCOME BACK!</h1>
            <div class="formContainer">
              <div class="formDiv" style="transition-delay: 0.2s">
                <p>EMAIL</p>
                <input type="email" v-model="email" required>
              </div>
              <div class="formDiv" style="transition-delay: 0.4s">
                <p>PASSWORD</p>
                <input type="password" v-model="password" required>
                <a href="#">FORGOT YOUR PASSWORD?</a>
              </div>
              <div class="formDiv" style="transition-delay: 0.6s">
                <button class="acceptBtn" type="submit">Login</button>
                <span class="register" @click="signupForm = true">Need an account? <a>Register</a></span>
              </div>
            </div>
          </form>
        </div>
        <div v-else>
          <form action="#" @submit.prevent="tryToRegisterIn">
            <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>Registration
              successfull.</b-alert>

            <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible
              :show="notificationAutoCloseDuration">{{ regError }}</b-alert>

            <b-alert :variant="notification.type" class="mt-3" v-if="notification.message"
              :show="notificationAutoCloseDuration" dismissible>{{ notification.message }}</b-alert>

            <div class="form-group">
              <label for="fullname">name</label>
              <input class="form-control" v-model="user.name" type="text" id="fullname" placeholder="Enter your name"
                :class="{
                  'is-invalid': submitted && $v.user.name.$error,
                }" />
              <div v-if="submitted && !$v.user.name.required" class="invalid-feedback">
                name is required.
              </div>
            </div>
            <div class="form-group">
              <label for="emailaddress">Email address</label>
              <input class="form-control" v-model="user.email" type="email" id="emailaddress"
                :class="{ 'is-invalid': submitted && $v.user.email.$error }" placeholder="Enter your email" />
              <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                <span v-if="!$v.user.email.required">Email is required.</span>
                <span v-if="!$v.user.email.email">Please enter valid email.</span>
              </div>
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <div class="input-group input-group-merge">
                <input :type="passwordType" v-model="user.password" id="password" class="form-control" :class="{
                  'is-invalid': submitted && $v.user.password.$error,
                }" placeholder="Enter your password" />
                <div class="input-group-append" @click="togglePassword()" data-password="false">
                  <div class="input-group-text">
                    <span class="password-eye"></span>
                  </div>
                </div>
                <div v-if="submitted && !$v.user.password.required" class="invalid-feedback">
                  Password is required.
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input v-model="termsConditions" type="checkbox" class="custom-control-input" id="checkbox-signup" />
                <label class="custom-control-label" for="checkbox-signup">
                  I accept
                  <a href="javascript: void(0);" class="text-dark">Terms and Conditions</a>
                </label>
              </div>
            </div>
            <div class="form-group mb-0 text-center">
              <button class="btn btn-primary btn-block" type="submit">
                Sign Up
              </button>
              <span class="register">Have an account? <a @click="showSignIn()">go to login</a></span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import axios from "axios"
import swal from "sweetalert"
export default {
  data() {
    return {
      name: '',
      selectedNames: [],
      passwordType: "password",
      termsConditions: false,
      signupForm: false,
      email: '',
      password: '',
      acceptContainerLoaded: false,
      user: {
        name: "",
        email: "",
        password: "",
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    }
  },
  validations: {
    user: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    async getscociete(){
        const tmp =  await axios.get('https://finex.4help.tn/api/v1/societes').then(res => { console.log(res.data); return res.data})
        tmp.map(obj => this.selectedNames.push(obj.Name) )
      },
      setsociete(name){
          localStorage.setItem('societe', JSON.stringify(name))
          axios.get(`https://finex.4help.tn/api/v1/societes/${name}`).then(res => { localStorage.setItem('matricule', res.data["Matricule"]) })          
      },
    togglePassword() {
      this.passwordType === "password" ? this.passwordType = "txt" : this.passwordType = "password"
    },
    async tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        await axios.post('https://finex.4help.tn/api/v1/register', this.user).then(async res => {
          this.registerSuccess = true
        })
      } catch (error) {
        this.isRegisterError = true
        this.regError = error.message
      }
    },
    async login() {
      try {
        await axios.post('https://finex.4help.tn/api/v1/login', { email: this.email, password: this.password }).then(async res => {
          localStorage.setItem('token', JSON.stringify(res.data.token))
          console.log(res.data.token)
          this.$router.push({ path: "/" })
        })
      } catch (error) {
        swal({
          title: 'Error',
          text: `Please veryify your credentials.`,
          icon: 'error',
        });
      }
    },
    showSignIn() {
      this.signupForm = false
      $(function () {
        $(".formDiv, form h1").addClass("loadIn");

      });
    }
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  async mounted() {
    await this.getscociete();
    console.log(this.selectedNames[0] )
    this.setsociete(this.selectedNames[0]);
    // JQUERY
    $(function () {
      var images = [
        "/images/login-bg.jpg",
        "/images/login-bg.jpg"
      ];

      $("#container").append(
        "<style>#container, .acceptContainer:before, #logoContainer:before {background: url(" +
        images[Math.floor(Math.random() * images.length)] +
        ") center fixed }"
      );

      setTimeout(function () {
        $(".logoContainer").transition({ scale: 1 }, 700, "ease");
        setTimeout(function () {
          $(".logoContainer .logo").addClass("loadIn");
          setTimeout(function () {
            $(".logoContainer .text").addClass("loadIn");
            setTimeout(function () {
              $(".acceptContainer").transition({ height: "100%" });
              setTimeout(function () {
                $(".acceptContainer").addClass("loadIn");
                setTimeout(function () {
                  $(".formDiv, form h1").addClass("loadIn");
                }, 500);
              }, 500);
            }, 500);
          }, 500);
        }, 1000);
      }, 10);
    });

  },
}

</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Work+Sans:300,400,700,900");

* {
  outline-width: 0;
  font-family: "Montserrat" !important;
}

body {
  background: #23272A;
}

#container {
  height: 100vh;
  background-size: cover !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

#inviteContainer {
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
}

#inviteContainer .acceptContainer {
  padding: 45px 30px;
  box-sizing: border-box;
  width: 400px;
  margin-left: -400px;
  overflow: hidden;
  height: 0;
  opacity: 0;
}

#inviteContainer .acceptContainer.loadIn {
  opacity: 1;
  margin-left: 0;
  transition: 0.5s ease;
}

#inviteContainer .acceptContainer:before {
  content: "";
  background-size: cover !important;
  box-shadow: inset 0 0 0 3000px rgba(3, 4, 94, 0.8);
  filter: blur(10px);
  position: absolute;
  width: 150%;
  height: 150%;
  top: -50px;
  left: -50px;
}

form {
  position: relative;
  text-align: center;
  height: 100%;
}

form h1 {
  margin: 0 0 15px 0;
  font-family: "Work Sans" !important;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0;
  left: -30px;
  position: relative;
  transition: 0.5s ease;
}

form h1.loadIn {
  left: 0;
  opacity: 1;
}

.formContainer {
  text-align: left;
}

.formContainer .formDiv {
  margin-bottom: 30px;
  left: -25px;
  opacity: 0;
  transition: 0.5s ease;
  position: relative;
}

.formContainer .formDiv.loadIn {
  opacity: 1;
  left: 0;
}

.formContainer .formDiv:last-child {
  padding-top: 10px;
  margin-bottom: 0;
}

.formContainer p {
  margin: 0;
  font-weight: 700;
  color: #aaa;
  font-size: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.formContainer input[type=password],
.formContainer input[type=email] {
  background: transparent;
  border: none;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.15);
  padding: 15px 0;
  box-sizing: border-box;
  color: #fff;
  width: 100%;
}

.formContainer input[type=password]:focus,
.formContainer input[type=email]:focus {
  box-shadow: inset 0 -2px 0 #fff;
}

.logoContainer {
  padding: 45px 35px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: scale(0, 0);
}

.logoContainer img {
  width: 150px;
  margin-bottom: -5px;
  display: block;
  position: relative;
}

.logoContainer img:first-child {
  width: 150px;
}

.logoContainer .text {
  padding: 25px 0 10px 0;
  margin-top: -70px;
  opacity: 0;
}

.logoContainer .text.loadIn {
  margin-top: 0;
  opacity: 1;
  transition: 0.8s ease;
}

.logoContainer .logo {
  width: 50px;
  position: relative;
  top: -20px;
  opacity: 0;
}

.logoContainer .logo.loadIn {
  top: 0;
  opacity: 1;
  transition: 0.8s ease;
}

.logoContainer:before {
  content: "";
  background-size: cover !important;
  position: absolute;
  top: -50px;
  left: -50px;
  width: 150%;
  height: 150%;
  filter: blur(10px);
  box-shadow: inset 0 0 0 3000px rgba(255, 255, 255, 0.8);
}

.forgotPas {
  color: #aaa;
  opacity: 0.8;
  text-decoration: none;
  font-weight: 700;
  font-size: 10px;
  margin-top: 15px;
  display: block;
  transition: 0.2s ease;
}

.forgotPas:hover {
  opacity: 1;
  color: #fff;
}

.acceptBtn {
  width: 100%;
  box-sizing: border-box;
  background: #1c2c38;
  border: none;
  color: #fff;
  padding: 20px 0;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.acceptBtn:hover {
  background: #294052e8;
}

.register {
  color: #aaa;
  font-size: 12px;
  padding-top: 15px;
  display: block;
}

.register a {
  color: #fff;
  text-decoration: none;
  margin-left: 5px;
  box-shadow: inset 0 -2px 0 transparent;
  padding-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.register a:hover {
  box-shadow: inset 0 -2px 0 #fff;
}
</style>